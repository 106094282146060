<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip pill small v-on="on">
            <v-avatar color="primary" left>
              <span class="white--text">{{ notification.name | initials }}</span>
            </v-avatar>
            {{ notification.name }}
          </v-chip>
        </template>
        <v-card width="300">
          <v-list dark>
            <v-list-item>
              <v-list-item-avatar color="primary">
                <span class="white--text">{{ notification.name | initials }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ notification.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ notification.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="menu = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-shape</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ notification.type }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-apps</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ notification.target }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-icon v-if="notification.enabled" color="success">mdi-bell</v-icon>
                    <v-icon v-else color="grey">mdi-bell</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle>{{ notification.enabled ? 'Enabled' : 'Disabled' }}</v-list-item-subtitle>
                </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </template>

  <script>
  export default {
    name: "Notification",

    data: () => ({
      menu: false,
    }),

    props: {
      notification: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
  }
  </script>
