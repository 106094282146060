<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <edit-dialog />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="showCreateDialog()">
          <v-icon class="mr-1">mdi-filter-plus</v-icon>
          New Search Filter
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-filter-outline" prominent type="info">
          <div>
            <h3>Refining Business Activity (IAO) Engagement with Search Filters</h3>
            <p>Search filters offer a robust mechanism for specifying the precise conditions under which individuals, on-call services, or teams should be engaged or notified during a business activity (IAO). These filters can be used to define the criteria for routing incidents to the right people, ensuring that the right people are engaged at the right time.</p>
          </div>
        </v-alert>

      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="ma-3 elevation-2">
                <v-card-title class="d-flex justify-space-between">
                  <search-filter :filter="item" />
                  <!-- Status Chip -->
                  <v-chip :color="item.enabled ? 'success' : 'grey'" class="ma-2">
                    <v-icon left>{{ item.enabled ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-off-outline' }}</v-icon>
                    {{ item.enabled ? 'Enabled' : 'Disabled' }}
                  </v-chip>
                </v-card-title>
                <v-card-subtitle class="px-4 py-4">
                  <!-- Notifications Menu -->
                  <v-menu offset-y v-if="item.notifications.length">
                    <template v-slot:activator="{ on }">
                      <v-chip color="cyan" text-color="white" v-on="on" class="ma-2">
                        <v-icon left>mdi-bell</v-icon>
                        {{ item.notifications.length }} Notifications
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="notification in item.notifications" :key="notification.id">
                        <!-- Assuming 'notification' is a component or template for displaying notification details -->
                        <notification :notification="notification" />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- Individuals Menu -->
                  <v-menu offset-y v-if="item.individuals.length">
                    <template v-slot:activator="{ on }">
                      <v-chip color="purple" text-color="white" v-on="on" class="ma-2">
                        <v-icon left>mdi-account-multiple</v-icon>
                        {{ item.individuals.length }} Individuals
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="individual in item.individuals" :key="individual.id">
                        <individual :individual="individual" />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- Teams Menu -->
                  <v-menu offset-y v-if="item.teams.length">
                    <template v-slot:activator="{ on }">
                      <v-chip color="blue" text-color="white" v-on="on" class="ma-2">
                        <v-icon left>mdi-account-group</v-icon>
                        {{ item.teams.length }} Teams
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="team in item.teams" :key="team.id">
                        <team :team="team" />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- Services Menu -->
                  <v-menu offset-y v-if="item.services.length">
                    <template v-slot:activator="{ on }">
                      <v-chip color="secondary" text-color="white" v-on="on" class="ma-2">
                        <v-icon left>mdi-check</v-icon>
                        {{ item.services.length }} Services
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="service in item.services" :key="service.id">
                        <service :service="service" />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-subtitle>
                <v-card-actions class="justify-end">
                  <v-icon left color="primary" @click="createEditShow(item)">mdi-eye</v-icon>
                  <v-icon left color="error" @click="removeShow(item)">mdi-delete</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/search/DeleteDialog.vue"
import SearchFilter from "@/search/SearchFilter.vue"
import Individual from "@/individual/Individual.vue"
import Team from "@/team/Team.vue"
import Service from "@/service/Service.vue"
import Notification from "@/notification/Notification.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import SearchFilterCreateDialog from "@/search/SearchFilterCreateDialog.vue"
import EditDialog from "@/search/EditDialog.vue"

export default {
  name: "SearchFilterTable",

  components: {
    DeleteDialog,
    SearchFilter,
    Individual,
    Team,
    Service,
    Notification,
    SettingsBreadcrumbs,
    SearchFilterCreateDialog,
    EditDialog,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", align: "left", width: "10%" },
        { text: "Description", value: "description", sortable: false },
        { text: "Expression", value: "expression", sortable: false },
        { text: "Individuals", value: "individuals" },
        { text: "Teams", value: "teams" },
        { text: "Services", value: "services" },
        { text: "Notifications", value: "notifications" },
        { text: "Creator", value: "creator.email" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Enabled", value: "enabled" },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      showEditSheet: false,
    }
  },

  computed: {
    ...mapFields("search", [
      "table.loading",
      "table.options.descending",
      "table.options.filters.project",
      "table.options.itemsPerPage",
      "table.options.page",
      "table.options.q",
      "table.options.sortBy",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  methods: {
    ...mapActions("search", ["getAll", "createEditShow", "removeShow", "showCreateDialog"]),
  },

  created() {
    this.project = [{ name: this.query.project }]
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },
}
</script>
